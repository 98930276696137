<template>
  <section>
    <div :class="(showButton ? '' : 'show-pointer')" style="position: relative;" @click="doClickCard(course.id)">
      <v-img class="rounded-lg" :alt="course.name" :aspect-ratio="1.2" :src="(course.images[0] !== undefined && 'url' in course.images[0] ? course.images[0].url : `https://via.placeholder.com/1920x1080.png?text=${course.name} Course`)">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-row>
        </template>
        <v-overlay :absolute="true" :opacity="0.4" z-index="4" class="primaryCourse insetShadow">
          <div style="position: relative; width: 100%; height: 100%;">
            <div>
              <v-avatar size="58" class="ma-2">
                <v-img contain :src="course.organization.logoUrl" :alt="course.organization.name"></v-img>
              </v-avatar>
              <span class="font-weight-bold">{{ course.organization.name }}</span>
            </div>
            <div class="pa-4" style="position: absolute; top: 0; right: 0;" v-if="showButton">
              <outlined-white-button @click="doClick(course.id)">Learn more</outlined-white-button>
            </div>
            <div class="pa-4" style="position: absolute; bottom: 0;">
              <div class="goBold-4 mt-1 mb-3">
                <p v-snip="{ lines: 2, mode: 'js', midWord: true }">{{ course.name }}</p>
              </div>
              <div class="gothic-1 mt-1">
                <p v-snip="{ lines: 3, mode: 'js', midWord: true }">{{ course.subtitle }}</p>
              </div>
            </div>
          </div>
        </v-overlay>
      </v-img>
    </div>
  </section>
</template>

<script>
import OutlinedWhiteButton from '@/components/outlinedWhiteButton.vue'

export default {
  name: 'courseCard',
  components: { OutlinedWhiteButton },
  props: {
    course: {
      type: Object
    },
    showButton: {
      type: Boolean,
      default: () => true
    }
  },
  data: () => ({

  }),
  methods: {
    doClick (id) {
      this.$emit('click', id)
    },
    doClickCard (id) {
      if (!this.showButton) {
        this.$emit('click', id)
      }
    }
  }
}
</script>

<style scoped>
.insetShadow {
  -webkit-box-shadow: inset 0px -50px 100px -20px rgba(0,0,0,1);
  -moz-box-shadow: inset 0px -50px 100px -20px rgba(0,0,0,1);
  box-shadow: inset 0px -50px 100px -20px rgba(0,0,0,1);
}

::v-deep .primaryCourse .v-overlay__content {
  width: 100%;
  height: 100%;
}
</style>
