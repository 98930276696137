<template>
  <section>
    <script v-html="googleCourseMetaList" type="application/ld+json"></script>
    <v-container class="mt-4">
      <div>
        <v-row no-gutters>
          <v-col cols="12">
            <div class="goBold-3">
              Courses available
            </div>
            <div class="gothic-2 mt-3">
              <span>Dive into our range of scuba courses available here in Phoenix, AZ.</span>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="mt-6 mb-n6">
        <v-row no-gutters>
          <v-col>
            <v-text-field outlined label="Search Courses" v-model="search">
              <font-awesome-icon slot="prepend-inner" :icon="['fas', 'magnifying-glass']" fixed-width class="mr-2 mt-1"></font-awesome-icon>
            </v-text-field>
          </v-col>
        </v-row>
      </div>
      <div v-if="isLoading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <div v-if="!isLoading && courses.length === 0" class="text-center">
        <v-btn outlined width="400" color="primary" class="mt-2" @click="doCallBusiness">
          <font-awesome-icon size="1x" :icon="['fa', 'phone']"></font-awesome-icon>
          <span class="gothic-1 ml-2">Call for available courses</span>
        </v-btn>
      </div>
      <div v-else class="mt-4">
        <v-row>
          <v-col cols="12" xl="3" lg="4" md="6" sm="6" v-for="course in currentCourses" :key="course.id">
            <course-card :course="course" @click="doOpenCourse"></course-card>
          </v-col>
        </v-row>
        <v-pagination
          class="my-4"
          v-model="page.current"
          :length="page.total"
          @input="doScrollToTop"
        ></v-pagination>
      </div>
    </v-container>
    <wave-effect></wave-effect>
  </section>
</template>

<script>
import CourseCard from '@/components/course/courseCard.vue'
import WaveEffect from '@/components/waveEffect.vue'

export default {
  name: 'About',
  components: { WaveEffect, CourseCard },
  data: () => ({
    isLoading: false,
    search: String(),
    maxPerPage: 8,
    page: {
      current: 1,
      total: 1
    },
    courses: [],
    forceDirect: false
  }),
  methods: {
    doOpenCourse (id) {
      this.$router.replace({ name: 'view-course', params: { id: id } })
    },
    doLoad () {
      this.isLoading = true

      this.$services.course.doGetAllPublic().then(courses => {
        this.courses = courses

        this.onResize()
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    onResize () {
      const innerWidth = window.innerWidth

      if (innerWidth < 600) {
        this.maxPerPage = 4
      } else if (innerWidth >= 600 && innerWidth < 960) {
        this.maxPerPage = 4
      } else if (innerWidth >= 960 && innerWidth < 1264) {
        this.maxPerPage = 9
      } else if (innerWidth >= 1264 && innerWidth < 1904) {
        this.maxPerPage = 8
      } else if (innerWidth >= 1904) {
        this.maxPerPage = 12
      } else {
        this.maxPerPage = 12
      }

      this.page.total = Math.ceil(this.allCoursesWithSearch.length / this.maxPerPage)
    }
  },
  mounted () {
    this.doLoad()
    if (this.$route.params.search) {
      this.search = this.$route.params.search
    }

    if (this.$route.params.forceDirect) {
      this.forceDirect = true
    }

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    allCoursesWithSearch () {
      if (this.search.length >= 3) {
        let foundExact = null

        const search = this.search.replace(/[^\w\s!?]/g, '').toLowerCase().trimEnd()

        const results = this.courses.filter(course => {
          if (course.name.toLowerCase() === search) {
            if (this.forceDirect) {
              this.doOpenCourse(course.id)
            }

            foundExact = course
          } else {
            return course.name.toLowerCase().includes(search)
          }
        })

        if (foundExact !== null) {
          return [foundExact]
        } else {
          return results
        }
      }

      return this.courses
    },
    currentCourses () {
      const pages = []

      for (let i = 0; i < this.allCoursesWithSearch.length; i += this.maxPerPage) {
        pages.push(this.allCoursesWithSearch.slice(i, i + this.maxPerPage))
      }

      return pages[this.page.current - 1]
    },
    googleCourseMetaList () {
      const courseListMeta = []

      this.courses.forEach(course => {
        courseListMeta.push(this.doGenerateCourse(course.name, course.subtitle, course.organization.name, course.organization.homepage))
      })

      const listObject = {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: courseListMeta
      }

      return listObject
    }
  },
  watch: {
    search () {
      this.page.current = 1
      this.page.total = Math.ceil(this.allCoursesWithSearch.length / this.maxPerPage)
    }
  },
  created () {
    this.doSetDocumentTitle('Courses and Classes', true)
  }
}
</script>

<style scoped lang="scss">
.title-gradient-background {
  background: rgb(2,0,36);
  background: linear-gradient(135deg, rgba(2,0,36,0.5) 0%, rgba(255,255,255,0.3) 100%);
}
</style>
